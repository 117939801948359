import { Box, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Input, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { NoAccounts, People, Visibility } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import BeneficiaryDetailedViewController from "../beneficiary/detailedview/DetailedViewController"
import { openModal } from "../../store/actions/modalAction"
import { memo, useCallback, useEffect, useRef } from "react"
import { StyledSearchBar } from "../../components/inputs/SearchBar"


const BeneficiaryComponent = memo(({ id, name, device_id, index, dispatchBeneficiaryList, err }) => {
    const dispatch = useDispatch()
    console.log("mounted", index)

    const ref = useRef()
    const onBenificaryClick = useCallback(() => {
        dispatch(openModal(<BeneficiaryDetailedViewController
            id={id}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])

    useEffect(() => {
        if (err && err != '' && ref.current) {
            ref.current.focus()
        }
    }, [err])
    return <>
        <TableRow>

            <TableCell>{name}</TableCell>
            <TableCell>
                <input
                    ref={ref}
                    style={{ height: "40px", width: "100%", background: "#f2f2f2", boxShadow: "none", border: `1px solid ` + ((err && err != '') ? "red" : "transparent"), padding: "5px 10px" }}
                    value={device_id}
                    placeholder="Add Device*"
                    onChange={(e) => {
                        dispatchBeneficiaryList({ type: "UPDATE", index: index, value: e.target.value })
                    }}
                />

            </TableCell>
            <TableCell>
                <Tooltip title="View Benficiary Details" >
                <IconButton onClick={onBenificaryClick}>
                    <People />
                </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    </>
})

const CloseEventUi = ({ data, loading, onSubmit, setData, title, modalKey, benificiarisList, dispatchBeneficiaryList, fetchLoading }) => {





    return (

        <CustomDialog
            onSubmit={onSubmit}
            id={modalKey}
            loading={loading || fetchLoading}
            title={title}
            closeText="Close"
            dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}

        >
            {
                loading || fetchLoading && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading && <Box sx={(theme) => ({ height: "100%", width: "100%", display: "flex", border: "1px solid", overflow: "hidden", [theme.breakpoints.down('md')]: { flexDirection: "column" } })}>
                <Box sx={{ display: "flex", borderRight: "1px solid black", flex: 1, overflow: "hidden", flexDirection: "column" }}>
                    <Box p={2} sx={{ display: "flex", background: "#f2f2f2" }}>
                        <Typography variant="button" textTransform="capitalize" >
                            {
                                data.name
                            }
                        </Typography>
                    </Box>
                    <Box p={2} sx={{ display: "flex" }}>
                        <Typography variant="body2" fontWeight="bold" >
                            Total Beneficiaries: ({benificiarisList && benificiarisList.length})
                        </Typography>
                    </Box>

                    <Box p={2} pt={0} sx={{ display: "flex",  overflowY: "auto", position: 'relative' }}>
                        <Table>
                            <TableHead sx={(theme) => ({ background: theme.palette.primary.main, position: "sticky", top: "0px", zIndex: 11, "*": { color: theme.palette.light.main + "!important" } })}>
                                <TableRow>
                                    <TableCell>Beneficiary</TableCell>
                                    <TableCell>Device</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    benificiarisList && Array.isArray(benificiarisList) && benificiarisList.length > 0 ? <>
                                        {
                                            benificiarisList.map((item, index) => <BeneficiaryComponent
                                                err={item.err}
                                                index={index}
                                                key={item._id}
                                                device_id={item.device_id}
                                                id={item._id}
                                                dispatchBeneficiaryList={dispatchBeneficiaryList}
                                                name={(item.name + " " + item.surname)} />)
                                        }
                                    </> : <NoDataComponent Icon={NoAccounts} message="No Beneficiary Assigned" />
                                }
                            </TableBody>
                        </Table>

                    </Box>
                </Box>

            </Box>
            }
        </CustomDialog>
    )

}
export default CloseEventUi