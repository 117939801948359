import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { addDocumentsApi } from "../../apis/documents.api";
import KeyDocumentCreateUi from "./KeyDocumentCreateUi";
import { addKeyDocumentsApi } from "../../apis/keydocument.api";

const KeyDocumentCreateController = ({ callBack = () => {}, id, type }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Key Document";
  const modalKey = "document";
  const createApi = addKeyDocumentsApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    title: "",
    document_url: "",
  });
  
  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.title,
        field: "Title",
      },
      {
        required: true,
        value: fields.document_url,
        field: "Document Url",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    createFunction();
  };

  return (
    <KeyDocumentCreateUi
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(KeyDocumentCreateController);
