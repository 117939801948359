import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const getKeyDocumentsApi = async params => {
  const callResponse = await axios({
    url: endpoints.keydocumentsBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getKeyDocumentByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.keydocumentById,
    method: "get",
    headers: getHeaders(),
    // responseType:"blob",
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const addKeyDocumentsApi = async data => {
  const callResponse = await axios({
    url: endpoints.keydocumentsBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteKeyDocumentsField = async data => {
  const callResponse = await axios({
    url: endpoints.keydocumentsBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


