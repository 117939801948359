import {
  Box,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import ImageComponent from "../../../components/inputs/ImageComponent";
import moment from "moment";
import UserProfileViewCommonController from "../../user/UserProfileViewCommonController";
import { getDonorByIdApi, getNgoByIdApi } from "../../../apis/user.api";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import BeneficiaryDetailedViewController from "../../beneficiary/detailedview/DetailedViewController";
import NoDataComponent from "../../../components/layouts/NoDataComponent";
import { HighlightOff, Info } from "@mui/icons-material";
import { memo, useCallback, useEffect, useState } from "react";
import { removeBeneficiaryToEventApi } from "../../../apis/event.api";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import { EVENT_STATUS } from "../../../utils/beneficiary.constant";

const ViewPanel = memo(
  ({
    beneficiary_id,
    name,
    device_id,
    onBenificaryClick,
    onDeleteBeneficiary,
    index,
    data,
  }) => {
    return (
      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell sx={{ textTransform: "capitalize" }}>{name}</TableCell>
        <TableCell>{device_id}</TableCell>
        <TableCell>
          <Tooltip title="View Beneficiary Details">
            <>
              <IconButton
                onClick={() => {
                  onBenificaryClick(beneficiary_id);
                }}
              >
                <Info />
              </IconButton>
              {data?.status == EVENT_STATUS.OPEN && (
                <IconButton
                  onClick={() => {
                    onDeleteBeneficiary(beneficiary_id);
                  }}
                >
                  <HighlightOff />
                </IconButton>
              )}
            </>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
);

const BeneficiaryDetails = ({ data, show }) => {
  const dispatch = useDispatch();
  const onBenificaryClick = useCallback((beneficiaryId) => {
    dispatch(
      openModal(
        <BeneficiaryDetailedViewController id={beneficiaryId} />,
        "md",
        false,
        "beneficiary-detailed-view"
      )
    );
  }, []);
  const [newBenficiaries, setNewBeneficiaries] = useState([]);
  const onDeleteBeneficiary = useCallback((beneficiaryId) => {
    DeleteBeneficiaryFromEvent(beneficiaryId);
  }, []);

  const DeleteBeneficiaryFromEvent = (id) => {
    dispatch(
      callApiAction(
        async () => await removeBeneficiaryToEventApi({ beneficiary_id: id }),
        async (response) => {
          const arr = [...newBenficiaries];
          const index = arr.findIndex((obj) => obj._id === id);
          if (index !== -1) {
            setNewBeneficiaries(...newBenficiaries, arr.splice(index, 1));
          }
          dispatch(
            callSnackBar(
              "Remove Beneficiary From Event",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };
  // useEffect(() => {
  //     // if(id)
  //     DeleteBeneficiaryFromEvent()
  // }, [])
  //     dispatch(openModal(<RemoveBeneficiaryFromController id={beneficiaryId}/>,"md", false, "event-details"))
  // })
  return (
    <>
      <Box sx={{ display: show ? "block" : "none", width: "100%" }}>
        <Typography fontWeight="bold" variant="body1">
          Total:{" "}
          {data.benificiaris && Array.isArray(data.benificiaris)
            ? data.benificiaris.length
            : 0}
        </Typography>

        <Table sx={{ width: "100%", overflowX: "scroll" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "100px" }}>Index No</TableCell>
              <TableCell sx={{ minWidth: "150px" }}>Name</TableCell>
              <TableCell sx={{ minWidth: "100px" }}>Device Id</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.benificiaris && Array.isArray(data?.benificiaris) ? (
              data?.benificiaris?.map((item, index) => (
                <ViewPanel
                  index={index}
                  key={
                    item?.beneficiary_id
                      ? item?.beneficiary_id?._id
                      : Math.random()
                  }
                  beneficiary_id={
                    item?.beneficiary_id ? item?.beneficiary_id?._id : null
                  }
                  name={
                    item?.beneficiary_id
                      ? item?.beneficiary_id?.surname +
                        " " +
                        item?.beneficiary_id?.name
                      : "NA"
                  }
                  device_id={item && item?.device_id ? item?.device_id : "NA"}
                  onBenificaryClick={onBenificaryClick}
                  onDeleteBeneficiary={onDeleteBeneficiary}
                  data={data}
                />
              ))
            ) : (
              <NoDataComponent message="No Data Found" />
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default memo(BeneficiaryDetails);
