import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import CustomInput from "../../components/inputs/CustomInput"
import { useSelector } from "react-redux"
import { HEARING_DIFFICULTY, HEARING_HISTORY_PROBLEM, HEARING_PROBLEM, LOSE_HEARING, SSA_REGISTERED } from "../../utils/beneficiary.constant"

const AdditionalDetailsFields = ({ show, fields, setFields, loading }) => {
    const { user } = useSelector(state => state)
    return <>
        <Box sx={{ width: "100%", display: show ? "block" : "none" }}>
        <Box>
        <Grid container columnSpacing={2} mb={2}>
  
  <Grid item xs={12} md={6}>
    <CustomInput
      disabled={loading}
      value={fields?.disablity_id_no || ""}
      onChange={(e) =>
        setFields({
          ...fields,
          disablity_id_no: e.target.value,
        })
      }
      type="text"
      label="Disability ID No"
    />
  </Grid>


  <Grid item xs={12} md={6}>
    <Box
      sx={{
        mt: 2,
        height: "82%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        border: "1px solid #DEDEDE",
        borderRadius: "4px",
        padding: "0 16px",
      }}
    >
    
      <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-start" }}>
        <Typography variant="body1" color="grey">
          SSA Registered
        </Typography>
      </Box>

      {/* Divider */}
      <Divider orientation="vertical" flexItem sx={{ mx: 2 , m:2 }} />

      {/* Radio Buttons Section */}
      <Box sx={{  alignItems: "center", justifyContent: "center" }}>
        <RadioGroup
          row
          value={fields.ssa_registered}
          onChange={(e) => {
            setFields({ ...fields, ssa_registered: e.target.value });
          }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </Box>
    </Box>
  </Grid>
</Grid>


    <Typography variant="h6" color={"grey"}>
      Family member information 
    </Typography>
  </Box>
            <Grid container columnSpacing={2} >
           

                

            <Box
                sx={{
                  mt: 2,
    
                  width:'100%',
                  display: "flex",
                
                  border: "1px solid #DEDEDE",
                  borderRadius: "4px",
                  justifyContent: "space-between",
                  p:3,
                  mb:2
                }}
              >
 <Grid container columnSpacing={2} >
           
            <Grid item xs={6}>
              <CustomInput
                disabled={loading}
                value={fields?.family_person_information?.person_name || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    family_person_information: {
                      ...fields.family_person_information,
                      person_name: e.target.value,
                    },
                  })
                }
                type="text"
                label="Person Name"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInput
                disabled={loading}
                value={fields?.family_person_information?.email || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    family_person_information: {
                      ...fields.family_person_information,
                      email: e.target.value,
                    },
                  })
                }
                type="text"
                label="Mail ID"
              />
            </Grid>

                

               

            <Grid item xs={6} md={6} >
              <CustomInput
                disabled={loading}
                value={fields?.family_person_information?.consanguinitu}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    family_person_information: {
                      ...fields.family_person_information,
                      consanguinitu: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Consanguinity"}
              />
            </Grid>

            <Grid item sm={6} xs={6}>
                    <CustomInput

                        disabled={loading}
                        value={fields.wehear_executive_name}
                        onChange={(e) => setFields({ ...fields, err: '', wehear_executive_name: e.target.value })}
                        type="text"
                        label={"Wehear executive Name"}

                    />
                </Grid>
                </Grid>

                </Box>
                
           

    <Box p={2}>
      <Typography color={"grey"} variant="h6" sx={{ mt: 2 }}>
        1. Please tell us about your hearing concerns:
      </Typography>
      <TextField
        value={fields.problems.please_tell_us_about_your_hearing_concerns}
        onChange={(e) =>
          setFields({
            ...fields,
            problems: {
              ...fields.problems,
              please_tell_us_about_your_hearing_concerns: e.target.value,
            },
          })
        }
        fullWidth
        multiline
        rows={2}
        placeholder="Your answer"
      />

      {/* Question 2 */}
      <Typography color={"grey"} variant="h6" sx={{ mt: 3 }}>
        2. My Hearing is:
      </Typography>
      <Box
                sx={{
                  mt: 2,
    
                  width:'100%',
                  display: "flex",
                
                  border: "1px solid #DEDEDE",
                  borderRadius: "4px",
                  justifyContent: "flex-start",
                  pl:3
                }}
              >
      <RadioGroup
        value={fields.problems.hearing}
        onChange={(e, changeVal) => {
          setFields({
            ...fields,
            problems: {
              ...fields.problems,
              hearing: changeVal,
            },
          });
        }}
        row
      >
        <FormControlLabel
          value={HEARING_PROBLEM["BETTER IN RIGHT EAR"]}
          control={<Radio />}
          label="Better in Right Ear"
        />
        <FormControlLabel
          value={HEARING_PROBLEM["BETTER IN LEFT EAR"]}
          control={<Radio />}
          label="Better in Left Ear"
        />
        <FormControlLabel
          value={HEARING_PROBLEM["ABOUT SAME IN BOTH EAR"]}
          control={<Radio />}
          label="About same in both ears"
        />
        <FormControlLabel
          value={HEARING_PROBLEM.UNSURE}
          control={<Radio />}
          label="Unsure"
        />
      </RadioGroup>
      </Box>

      {/* Question 3 */}
      <Typography color={"grey"} variant="h6" sx={{ mt: 3 }}>
        3. Do you have difficulty hearing:
      </Typography>
      <Box  sx={{
                  mt: 2,
    
                  width:'100%',
                  display: "flex",
                
                  border: "1px solid #DEDEDE",
                  borderRadius: "4px",
                  justifyContent: "flex-start",
                  pl:3
                }}
              >
      <Grid container spacing={2}>
        {[
          "noisy_place",
          "quiet_place",
          "restaurant",
          "television",
          "telephone",
          "one_on_one_talking",
        ].map((option, index) => (
          <Grid item xs={4} key={index}>
            <FormControlLabel
              value={option}
              onChange={(e) => {
                setFields({
                  ...fields,
                  problems: {
                    ...fields.problems,
                    hearing_difficulty: {
                      ...fields.problems.hearing_difficulty,
                      [option]: e.target.checked,
                    },
                  },
                });
              }}
              control={
                <Checkbox
                  checked={
                    fields?.problems?.hearing_difficulty?.[option] || false
                  }
                />
              }
              label={HEARING_DIFFICULTY[index]}
            />
          </Grid>
        ))}
      </Grid>
      </Box>

{/*Question 4 */}
<Box sx={{ mt: 3 }}>
  <Typography color={"grey"} variant="h6">
    4. Do you have history of:
  </Typography>
  <Box
    sx={{
      mt: 2,
      width: "100%",
      display: "flex",
      border: "1px solid #DEDEDE",
      borderRadius: "4px",
      justifyContent: "flex-start",
      pl: 3,
    }}
  >
    <Grid container spacing={1}>

      {[
        { label: "Ear Pain", key: "ear_pain" },
        { label: "Ear Infection", key: "ear_infection" },
        { label: "Ear Surgery", key: "ear_surgery" },
        { label: "Fullness in Ear", key: "fullness_in_ear" },
        { label: "Tinnitus", key: "tinnitus" },
        { label: "Fluctuation in hearing", key: "fluctuation_in_hearing" },
      ].map((option, index) => (
        <Grid
          container
          item
          xs={6}
          key={index}
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={4}>
            <Typography color={"grey"} variant="body1">
              {option.label}:
            </Typography>
          </Grid>
          <Grid item>
            <RadioGroup
              row
              value={
                fields?.problems?.hearing_history?.[option.key] || HEARING_HISTORY_PROBLEM.NO
              }
              onChange={(e) => {
                setFields((prevFields) => ({
                  ...prevFields,
                  problems: {
                    ...prevFields.problems,
                    hearing_history: {
                      ...prevFields.problems.hearing_history,
                      [option.key]: e.target.value, 
                    },
                  },
                }));
              }}
            >
              <FormControlLabel
                value={HEARING_HISTORY_PROBLEM.RIGHT}
                control={<Radio />}
                label="Right"
              />
              <FormControlLabel
                value={HEARING_HISTORY_PROBLEM.LEFT}
                control={<Radio />}
                label="Left"
              />
              <FormControlLabel
                value={HEARING_HISTORY_PROBLEM.BOTH}
                control={<Radio />}
                label="Both"
              />
              <FormControlLabel
                value={HEARING_HISTORY_PROBLEM.NO}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      ))}

      {/* Checkbox for Other Problems */}
      {[
        { label: "Head Ache", key: "head_ache" },
        { label: "Dizziness Vomiting", key: "dizziness_vomiting" },
        { label: "Noise Exposure", key: "noise_exposure" },
      ].map((option, index) => (
        <Grid item xs={4} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={fields?.problems?.hearing_history?.[option.key] || false}
                onChange={(e) => {
                  setFields((prevFields) => ({
                    ...prevFields,
                    problems: {
                      ...prevFields.problems,
                      hearing_history: {
                        ...prevFields.problems.hearing_history,
                        [option.key]: e.target.checked, 
                      },
                    },
                  }));
                }}
              />
            }
            label={option.label}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
</Box>

      {/* Question 5 */}
      <Typography color={"grey"} variant="h6" sx={{ mt: 2 }}>
        5. Do you have:
      </Typography>
      <Box
                sx={{
                  mt: 2,
    
                  width:'100%',
                  display: "flex",
                
                  border: "1px solid #DEDEDE",
                  borderRadius: "4px",
                  justifyContent: "flex-start",
                  pl:3
                }}
              >
      <Grid container spacing={2}>
        {[
          { label: "Diabetes", key: "diabetes" },
          { label: "Blood Pressure", key: "blood_pressure" },
          { label: "Heart Problem", key: "heart_problem" },
          { label: "Kidney Disease", key: "kidney_disease" },
          { label: "Tuberculosis", key: "tuberculosis" },
          { label: "Cancer", key: "cancer" },
          { label: "Other", key: "other" },
          {
            label: "Are you currently taking any medication?",
            key: "taking_medication",
          },
        ].map((option, index) => (
          <Grid item xs={4} key={index}>
            <FormControlLabel
              onChange={(e) => {
                setFields({
                  ...fields,
                  problems: {
                    ...fields.problems,
                    medical_history: {
                      ...fields.problems.medical_history,
                      [option.key]: e.target.checked,
                    },
                  },
                });
              }}
              control={
                <Checkbox
                  checked={fields?.problems?.medical_history?.[option.key]}
                />
              }
              label={option.label}
            />
          </Grid>
        ))}
      </Grid>
      </Box>

      {/* Question 6 */}
      <Typography color={"grey"} variant="h6" sx={{ mt: 2 }}>
        6. Do you have hearing aid:
      </Typography>
      <Box
                sx={{
                  mt: 2,
    
                  width:'100%',
                  display: "flex",
                
                  border: "1px solid #DEDEDE",
                  borderRadius: "4px",
                  justifyContent: "flex-start",
                  pl:3
                }}
              >
<RadioGroup
  value={fields?.problems?.hearing_aid?.had_hearing_aid?.toString() || ""}
  onChange={(e) => {
    const value = e.target.value === "true"; 
    setFields((prevFields) => ({
      ...prevFields,
      problems: {
        ...prevFields.problems,
        hearing_aid: {
          ...prevFields.problems.hearing_aid,
          had_hearing_aid: value,
        },
      },
    }));
  }}
  row
>
  <FormControlLabel
    value="true"
    control={<Radio />}
    label="YES"
  />
  <FormControlLabel
    value="false"
    control={<Radio />}
    label="NO"
  />
</RadioGroup>

      </Box>
      <TextField
  value={fields?.problems?.hearing_aid?.model || ""}
  onChange={(e) => {
    setFields((prevFields) => ({
      ...prevFields,
      problems: {
        ...prevFields.problems,
        hearing_aid: {
          ...prevFields.problems.hearing_aid,
          model: e.target.value,
        },
      },
    }));
  }}
  fullWidth
  placeholder="Model Name"
  sx={{ mt: 1 }}
/>


      {/* Question 7 */}
      <Typography color={"grey"} variant="h6" sx={{ mt: 2 }}>
        7. Do you have any family member with hearing loss?
      </Typography>
      <Box
                sx={{
                  mt: 2,
    
                  width:'100%',
                //   display: "flex",
                
                  border: "1px solid #DEDEDE",
                  borderRadius: "4px",
                  
                  p:2
                }}
              >
     <RadioGroup
  value={fields?.problems?.family_member_hearing_loss?.has_family_member_with_hearing_loss?.toString() || ""} // Convert boolean to string
  onChange={(e) => {
    const value = e.target.value === "true"; 
    setFields((prevFields) => ({
      ...prevFields,
      problems: {
        ...prevFields.problems,
        family_member_hearing_loss: {
          ...prevFields.problems.family_member_hearing_loss,
          has_family_member_with_hearing_loss: value,
        },
      },
    }));
  }}
  row
>
  <FormControlLabel
    value="true"
    control={<Radio />}
    label="YES"
  />
  <FormControlLabel
    value="false"
    control={<Radio />}
    label="NO"
  />
</RadioGroup>


      <TextField
  value={fields?.problems?.family_member_hearing_loss?.who || ""}
  onChange={(e) => {
    setFields((prevFields) => ({
      ...prevFields,
      problems: {
        ...prevFields.problems,
        family_member_hearing_loss: {
          ...prevFields.problems.family_member_hearing_loss,
          who: e.target.value,
        },
      },
    }));
  }}
  fullWidth
  placeholder="Who?"
  sx={{ mt: 1 }}
/>

      <Typography color={"grey"} variant="body1" sx={{ mt: 1 }}>
        When did the family member lose hearing?
      </Typography>
      
<RadioGroup
  value={fields?.problems?.family_member_hearing_loss?.when_lost_hearing || ""} 
  onChange={(e, change) => {
    setFields({
      ...fields,
      problems: {
        ...fields.problems,
        family_member_hearing_loss: {
          ...fields.problems.family_member_hearing_loss,
          when_lost_hearing: parseInt(change), 
        },
      },
    });
  }}
  row
>
  <FormControlLabel
    value={LOSE_HEARING.BIRTH}
    control={<Radio />}
    label="Birth"
  />
  <FormControlLabel
    value={LOSE_HEARING.MID_LIFE}
    control={<Radio />}
    label="Mid-life"
  />
  <FormControlLabel
    value={LOSE_HEARING.LIFE_ONSET}
    control={<Radio />}
    label="Life-onset"
  />
</RadioGroup>
<TextField
  value={fields?.problems?.family_member_hearing_loss?.additional_information || ""}
  onChange={(e) => {
    setFields((prevFields) => ({
      ...prevFields,
      problems: {
        ...prevFields.problems,
        family_member_hearing_loss: {
          ...prevFields.problems.family_member_hearing_loss,
          additional_information: e.target.value,
        },
      },
    }));
  }}
  multiline
  rows={2}
  placeholder="Any other information you would like to share:"
  sx={{ mt: 1 , width:'100%'}}
/>

      </Box>

      {/* Conclusion */}
      <Typography color={"grey"} variant="h6" sx={{ mt: 2 }}>
        8. Conclusion:
      </Typography>
      <TextField
  value={fields?.problems?.conclusion || ""} 
  onChange={(e) => {
    setFields({
      ...fields,
      problems: {
        ...fields.problems,
        conclusion: e.target.value,
      },
    });
  }}
  multiline
  rows={3}
  placeholder="Conclusion"
  fullWidth
/>

    </Box>
               </Grid>
        </Box>
    </>
}
export default AdditionalDetailsFields