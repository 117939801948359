import { Box, Button, ButtonBase, Chip, CircularProgress, Grid, IconButton, Typography, styled } from "@mui/material"
import ImageComponent from "../../../components/inputs/ImageComponent"
import moment from "moment"
import { createFullName, findObjectKeyByValue, titleCase } from "../../../utils/helper"
import { GENDER, SNACK_BAR_VARIETNS } from "../../../utils/constants"
import FileDownloadComponent from "../../../components/FileDownloadComponent"
import { BENIFICIARY_STATUS,  HEARING_HISTORY_PROBLEM, HEARING_PROBLEM, LOSE_HEARING, SSA_REGISTERED } from "../../../utils/beneficiary.constant"
import EventDetailedViewController from "../../event/detailedview/DetailedViewController"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import MessageDilog from "../../../components/MessageDilog"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { callApiAction } from "../../../store/actions/commonAction"
import { removeBeneficiaryToEventApi } from "../../../apis/event.api"
import MODULES from "../../../utils/module.constant"
import SubmitButton from "../../../components/button/SubmitButton"
import { Preview, Slideshow } from "@mui/icons-material"

const Header = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottom: "0px",
    borderTopRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const Body = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),

    marginBottom: theme.spacing(2),

    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))

const EventViewRemoveButtonButton = ({ beneficiary_id, event, data, setData }) => {
    const dispatch = useDispatch()
    const {user} = useSelector(state=>state)
    const allowedModules = user.data.modules ?? []
    const [loading, setLoading] = useState(false)
    const onEventView = () => {
        dispatch(openModal(<EventDetailedViewController
            id={event?._id}



        />, "md", false, "event-detailed-view"))


    }
    const onRemoveEvent = (e) => {
        e?.preventDefault()
        setLoading(true)
        dispatch(closeModal('error'))
        dispatch(
            callApiAction(
                async () => await removeBeneficiaryToEventApi({ beneficiary_id }),
                async (response) => {
                    setData({ ...data, ...response })
                    setLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false)
                }
            )
        )
    }
    const onRemovebtnClick = () => {
        dispatch(
            openModal(<MessageDilog onSubmit={onRemoveEvent} message={`Are you sure to remove ${data.surname ?? ""} ${data.name ?? ""} from event ${event?.name}?`} title="Remove Alert!" />, "sm", undefined, 'error')
        )
    }
    if (loading)
        return <CircularProgress />
    return <Typography variant="body2" textTransform="capitalize">{event ?
        <Chip variant="text" size="small" onClick={onEventView} label={event.name} onDelete={allowedModules.includes(MODULES.EVENT_BENIFICIARY_ASSIGN)?onRemovebtnClick:undefined}></Chip>
        : "Yet To Donate"}</Typography>
}
const BeneficiaryPersonalDeatils = ({ data, setData }) => {
    const dispatch = useDispatch()

    
const HEARING_DIFFICULTY = {
        quiet_place: "Quiet Place",
        restaurant: "Restaurant",
        one_on_one_talking: "One-on-One Talking",
        noisy_place: "Noisy Place",
        television: "Television",
        telephone: "Telephone"
    };
    
    return <>
        <Header>
            <Typography fontWeight="bold" variant="body1">
                Personal Details
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>

                    <Grid container spacing={2}>
                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Name:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2" textTransform="capitalize" > {createFullName(data.surname, data.name)}</Typography>
                        </Grid>






                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Phone:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2">{data.phone}</Typography>
                        </Grid>


                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Email:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2">{data.email ? data.email : "NA"}</Typography>
                        </Grid>
                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Gender : </Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2">{findObjectKeyByValue(data.gender, GENDER)}</Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">UDISE No:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2">{data.udise_no}</Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Aadhar No:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2">{data.aadhar}</Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Has Aadhar Card?:</Typography>
                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Box sx={{ display: "flex", alignItems: "center" }} >
                                <Typography variant="body2" lineHeight="100%" >
                                    {data.aadhar_file && data.aadhar_file != '' ? "YES" : "NO"}
                                </Typography>
                                <Typography variant="body2" lineHeight="100%" ml={2} >
                                    {
                                        data.aadhar_file && data.aadhar_file == 'true' ? "File is merged in some other document" : ""
                                    }
                                    {
                                        data.aadhar_file && data.aadhar_file != '' && data.aadhar_file != 'true' ? <FileDownloadComponent iconStyle={{ fontSize: "inherit", padding: 1 }} src={data.aadhar_file} view /> : ""
                                    }
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Has Disablity Certificate?:</Typography>
                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Box sx={{ display: "flex", alignItems: "center" }} >
                                <Typography variant="body2" lineHeight="100%" >
                                    {data.udise_file && data.udise_file != '' ? "YES" : "NO"}
                                </Typography>
                                <Typography variant="body2" lineHeight="100%" ml={2} >
                                    {
                                        data.udise_file && data.udise_file == 'true' ? "File is merged in some other document" : ""
                                    }
                                    {
                                        data.udise_file && data.udise_file != '' && data.udise_file != 'true' ? <FileDownloadComponent iconStyle={{ fontSize: "inherit", padding: 1 }} src={data.udise_file} view /> : ""
                                    }
                                </Typography>

                            </Box>
                            {/* {data.udise_file && data.udise_file != '' ? "YES" : "NO"} */}
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Has Income Certificate?:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Box sx={{ display: "flex", alignItems: "center" }} >
                                <Typography variant="body2" lineHeight="100%" >
                                    {data.income_certi && data.income_certi != '' ? "YES" : "NO"}
                                </Typography>
                                <Typography variant="body2" color="gray" lineHeight="100%" ml={2} >
                                    {
                                        data.income_certi && data.income_certi == 'true' ? "File is merged with above document" : ""
                                    }
                                    {
                                        data.income_certi && data.income_certi != '' && data.income_certi != 'true' ? <FileDownloadComponent iconStyle={{ fontSize: "inherit", padding: 1 }} src={data.income_certi} view /> : ""
                                    }
                                </Typography>

                            </Box>
                            {/* {data.income_certi && data.income_certi != '' ? "YES" : "NO"} */}
                        </Grid>
                        {/* <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Documents:</Typography>
                        </Grid>
                        <Grid item md={8} xs={6} >
                            {data.udise_file ? <FileDownloadComponent src={data.udise_file} /> : "NA"}
                        </Grid> */}




                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ width: "200px", maxWidth: "100%" }}>


                        <ImageComponent src={
                            data.photo ? data.photo :
                                (data.gender == GENDER.MALE
                                    ?
                                    "https://firebasestorage.googleapis.com/v0/b/wehear-bdms/o/bdms-production%2F64953cb5427ff5d89667bd23-1690353651311.png?alt=media&token=05788209-d59a-440b-98df-cacacf154559"
                                    :
                                    "https://firebasestorage.googleapis.com/v0/b/wehear-bdms/o/bdms-production%2F64953cb5427ff5d89667bd23-1690353662831.jpg?alt=media&token=d1f67987-c82b-448c-888e-901d7e29c281"
                                )
                        }
                            avtarTitle={data?.name?.slice?.(0, 1).toUpperCase?.()} ></ImageComponent>

                        <Typography fontWeight="bold" variant="body2">DOB: {moment(data.dob).format("DD / MM / YYYY")}</Typography>

                    </Box>
                </Grid>



            </Grid>
        </Body>



        <Header>
            <Typography fontWeight="bold" variant="body1">
                Address Details
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Country:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.country ? data.country.name : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">State:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.state ? data.state.name : "NA"}</Typography>
                </Grid>




                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">District:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.district ? data.district.name : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Taluka:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.taluka ? data.taluka.name : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">PIN:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.PIN ? data.PIN : "NA"}</Typography>
                </Grid>

                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Address:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.addres_line ? data.addres_line : "NA"}</Typography>
                </Grid>



            </Grid>
        </Body>




        <Header>
            <Typography fontWeight="bold" variant="body1">
                Guardian Details
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Father Name:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.father_name ? data.father_name : "NA"}</Typography>
                </Grid>



                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Mother Name:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.mother_name ? data.mother_name : "NA"}</Typography>
                </Grid>



                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2" >Father Occupation:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.father_occupation ? data.father_occupation : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Mother Occupation:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.mother_occupation ? data.mother_occupation : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Family Yearly Income:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" >{data.family_income ? data.family_income : "NA"}</Typography>
                </Grid>

                {/* <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Income Certi:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    {data.income_certi ? <FileDownloadComponent src={data.income_certi} /> : "NA"}
                </Grid> */}
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Guardian Contact:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.family_contact ? data.family_contact : "NA"}</Typography>
                </Grid>
            </Grid>
        </Body>




        <Header>
            <Typography fontWeight="bold" variant="body1">
                Other  Details
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Status:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2"><Chip size="small" color="info" label={findObjectKeyByValue(data.status, BENIFICIARY_STATUS)} /></Typography>
                </Grid>

                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Camp:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.camp ? data.camp.name : "NA"}</Typography>
                </Grid>

                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Teacher:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.teacher ? data.teacher.name : "NA"}</Typography>
                </Grid>






                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Donors:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.donors && Array.isArray(data.donors) && data.donors.length > 0 ? data.donors.map((item) => item.name).join(', ') : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Ngos:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.ngos && Array.isArray(data.ngos) && data.ngos.length > 0 ? data.ngos.map((item) => item.name).join(', ') : "NA"}</Typography>
                </Grid>





                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Donation Event:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    {/* <Typography variant="body2" textTransform="capitalize">{data.donation_event_id ? <Chip variant="text" size="small" onClick={onEventView} label={data.donation_event_id.name}></Chip> : "Yet To Donate"}</Typography> */}
                    <EventViewRemoveButtonButton beneficiary_id={data._id} event={data.donation_event_id} data={data} setData={setData} />
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Pre Donation Video 1:</Typography>

                </Grid>
                    <Grid item md={3} xs={6}>
                       <Typography variant="body2" >
                                    {
                                        data.before_beneficiary_video && data.before_beneficiary_video != '' ? <IconButton href={data.before_beneficiary_video} sx={{ fontSize: "inherit",marginTop:"-10px" }} target="_blank" color="primary"><Preview /></IconButton> : "NA"
                                    }
                                </Typography>
                    </Grid>
                    <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Pre Donation Video 2:</Typography>

                </Grid>
                    <Grid item md={3} xs={6}>
                    <Typography variant="body2">
                    {
                                        data.after_beneficiary_video && data.after_beneficiary_video != '' ? <IconButton href={data.after_beneficiary_video} sx={{ fontSize: "inherit",marginTop:"-10px" }} target="_blank" color="primary"><Preview /></IconButton> : "NA"
                                    }
                    </Typography>
                    </Grid>

            </Grid>
        </Body>

       
    </>
}
export default BeneficiaryPersonalDeatils