import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
// import { deleteUserApi, getUserApi, updateUserField } from "../../apis/user.api"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { findNameByRole, findObjectKeyByValue, titleCase } from "../../utils/helper"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import { Delete, Edit } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import moment from "moment";
import { getDeleteLogsApi,addDeleteLogsApi } from "../../apis/deletelogs.api"
import { REGIONS_TYPE } from "../../utils/constants"

const ActionComponent = memo(({ params, setParams, deleteApi, modalKey }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)


    const onDelete = () => {
        dispatch(openModal(<MessageDilog
            title="Alert!"
            message={"Are you sure to delete " + params.name }

            onSubmit={(e) => {
                e.preventDefault()
                setLoading(true)
                console.log(deleteApi)
                dispatch(callApiAction(
                    async () => await deleteApi({ id: params._id }),
                    
                    (response) => {
                        setParams({})
                        setLoading(false)
                        dispatch(closeModal('delete'))
                    },
                    (err) => {
                        setLoading(false)
                    }
                ))
            }}

        />, "sm", false, "delete"))
    }

    if (loading) {
        return <CircularProgress />
    }
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
        </IconButton>

    </Box>
})

const ListController = () => {
    const dispatch = useDispatch()

    const title = "Delete Logs "
    const modalKey = 'delete logs'
    const fetchApi = getDeleteLogsApi

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search:'',
        searchable: ['user_id.name'],
        sort: '',
        sortDirection: 1

    })

    const columns = useMemo(() => {
       
        const arr = [
            { id: 1, fieldName: 'createdAt', label: 'Date', align: "left", sort: true,renderValue: (params, setParams) => moment(params.createdAt).format("DD/MM/YYYY") },

            { id: 2, fieldName: 'user_id', label: 'User Name', align: "left", sort: true ,renderValue: (params, setParams) => params.user_id.name},

            { id: 3, fieldName: 'total_entry_deleted', label: 'Total Entry Deleted', align: "left" },

        ]
        return [...arr]
    }, [dispatch, filters]);
    console.log("0000",filters)


    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}
            />

        </>
    )
}
export default ListController;