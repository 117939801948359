import { Box, Button, ButtonBase, Chip, CircularProgress, Grid, IconButton, Typography, styled } from "@mui/material"
import ImageComponent from "../../../components/inputs/ImageComponent"
import moment from "moment"
import { createFullName, findObjectKeyByValue, titleCase } from "../../../utils/helper"
import { GENDER, SNACK_BAR_VARIETNS } from "../../../utils/constants"
import FileDownloadComponent from "../../../components/FileDownloadComponent"
import { BENIFICIARY_STATUS,  HEARING_HISTORY_PROBLEM, HEARING_PROBLEM, LOSE_HEARING, SSA_REGISTERED } from "../../../utils/beneficiary.constant"
import EventDetailedViewController from "../../event/detailedview/DetailedViewController"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import MessageDilog from "../../../components/MessageDilog"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { callApiAction } from "../../../store/actions/commonAction"
import { removeBeneficiaryToEventApi } from "../../../apis/event.api"
import MODULES from "../../../utils/module.constant"
import SubmitButton from "../../../components/button/SubmitButton"
import { Preview, Slideshow } from "@mui/icons-material"

const Header = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottom: "0px",
    width: "100%",
}));

const Body = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    width: "100%",
}));

const AdditionalDetails = ({ data, setData }) => {
    const HEARING_DIFFICULTY = {
        quiet_place: "Quiet Place",
        restaurant: "Restaurant",
        one_on_one_talking: "One-on-One Talking",
        noisy_place: "Noisy Place",
        television: "Television",
        telephone: "Telephone",
    };

    return (
        <>
            <Header>
                <Typography fontWeight="bold" variant="h6">
                    Additional Details
                </Typography>
            </Header>
            <Body>
                <Grid container spacing={3} alignItems="center">
                    {/* Person Name */}
                    <Grid item xs={6} md={3}>
                        <Typography fontWeight="bold" variant="body4">
                            Person Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" textTransform="capitalize">
                            {data?.family_person_information?.person_name || "NA"}
                        </Typography>
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} md={3}>
                        <Typography fontWeight="bold" variant="body4">
                            Mail ID:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="body2" textTransform="capitalize">
                            {data?.family_person_information?.email || "NA"}
                        </Typography>
                    </Grid>

                    {/* Consanguinity */}
                    <Grid item xs={12} md={3}>
                        <Typography fontWeight="bold" variant="body4">
                            Consanguinity:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="body2" textTransform="capitalize">
                            {data?.family_person_information?.consanguinitu || "NA"}
                        </Typography>
                    </Grid>

                    {/* SSA Registered */}
                    <Grid item xs={12} md={3}>
                        <Typography fontWeight="bold" variant="body4">
                            SSA Registered:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="body2">
                            {findObjectKeyByValue(data?.ssa_registered, SSA_REGISTERED) || "NA"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                    <Typography fontWeight="bold" variant="body4">Disability ID No:</Typography>

                </Grid>
                <Grid item xs={12} md={3}>
                <Typography variant="body2">{data?.disablity_id_no ? data?.disablity_id_no : "NA"}</Typography>
                </Grid>

                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body4">Wehear executive Name:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data?.wehear_executive_name ? data?.wehear_executive_name : "NA"}</Typography>
                </Grid>
                </Grid>
            </Body>

            <Header>
                <Typography fontWeight="bold" variant="h6">
                    Additional Hearing Details
                </Typography>
            </Header>
            <Body>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
    <Typography fontWeight="bold" variant="body4">About their hearing concerns:</Typography>
</Grid>
<Grid item xs={8}>
    <Typography variant="body2">
        {data?.problems?.please_tell_us_about_your_hearing_concerns || "NA"}
    </Typography>
</Grid>

<Grid item xs={4}>
    <Typography fontWeight="bold" variant="body4">Their Hearing is:</Typography>
</Grid>
<Grid item xs={8}>
    <Typography variant="body2">
        {findObjectKeyByValue(data?.problems?.hearing, HEARING_PROBLEM) || "NA"}
    </Typography>
</Grid>

                    {/* Hearing Difficulty */}
                    <Grid item xs={4}>
                        <Typography fontWeight="bold" variant="body4">
                            They have difficulty hearing:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {Object.entries(data?.problems?.hearing_difficulty || {})
                                .filter(([_, value]) => value)
                                .map(([key]) => HEARING_DIFFICULTY[key])
                                .join(", ") || "NA"}
                        </Typography>
                    </Grid>

                    {/* Medical History */}
                    <Grid item xs={4}>
                        <Typography fontWeight="bold" variant="body4">
                            Medical History:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {Object.entries(data?.problems?.medical_history || {})
                                .filter(([_, value]) => value === true)
                                .map(([key]) => titleCase(key.replace("_", " ")))
                                .join(", ") || "NA"}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
    <Typography fontWeight="bold" variant="body4">Family member with hearing loss:</Typography>
</Grid>
<Grid item xs={8}>
    <Typography variant="body2">
        {data?.problems?.family_member_hearing_loss?.has_family_member_with_hearing_loss
            ? `Who: ${data?.problems?.family_member_hearing_loss?.who}, When: ${findObjectKeyByValue(data?.problems?.family_member_hearing_loss?.when_lost_hearing, LOSE_HEARING)}, Additional Info: ${data?.problems?.family_member_hearing_loss?.additional_information || "NA"}`
            : "NA"}
    </Typography>
</Grid>

                    {/* Hearing Aid */}
                    <Grid item xs={4}>
                        <Typography fontWeight="bold" variant="body4">
                            They have a hearing aid:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {data?.problems?.hearing_aid?.had_hearing_aid
                                ? `Model: ${data?.problems?.hearing_aid?.model || "NA"}`
                                : "No"}
                        </Typography>
                    </Grid>

                    {/* Hearing History */}
                    <Grid item xs={4}>
                        <Typography fontWeight="bold" variant="body4">
                            Hearing History Includes:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <Typography variant="body2">
    {Object.entries(data?.problems?.hearing_history || {})
        .filter(([key, value]) => value !== false && value !== null)
        .map(([key, value]) => {
            const displayValue =
                value === true
                    ? titleCase(key.replace("_", " ")) 
                    : typeof value === "number"
                    ? findObjectKeyByValue(value, HEARING_HISTORY_PROBLEM) || value 
                    : titleCase(key.replace("_", " ")); 
            
            return value === true
                ? displayValue
                : `${titleCase(key.replace("_", " "))}: ${displayValue}`;
        })
        .join(", ") || "NA"}
</Typography>

                    </Grid>
                    
<Grid item xs={4}>
    <Typography fontWeight="bold" variant="body4">Conclusion:</Typography>
</Grid>
<Grid item  xs={8}>
    <Typography variant="body2">
        {data?.problems?.conclusion || "NA"}
    </Typography>
</Grid>
                </Grid>
            </Body>
        </>
    );
};


export default AdditionalDetails;