import {
  Autocomplete,
  CircularProgress,
  ListItem,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInput";
import {
  REGIONS_TYPE,
  THIRD_PART_APPS_USED,
  USER_ROLES,
} from "../../utils/constants";
import {
  findNameByRole,
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../utils/helper";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { getRegionApi } from "../../apis/region.api";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import SubmitButton from "../../components/button/SubmitButton";
import FileInput from "../../components/inputs/FileInput";

const KeyDocumentCreateUi = ({
  title,
  modalKey,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const { user } = useSelector((state) => state);

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.title}
                onChange={(e) =>
                  setFields({ ...fields, err: "", title: e.target.value })
                }
                type="text"
                label={"Document Name*"}
              />
            }

            {/* <FileDownloadComponent
              key={user.data.first_name}
              downloadName={`${user.data.first_name}.pdf`}
              src={fields.document_url}
              Component={({ loading, ...props }) => (
                <Box mt={8} sx={{ float: "right", marginTop: "15px" }}>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <SubmitButton
                      {...props}
                      disabled={loading}
                      title={"Upload Document"}
                    />
                  )}
                </Box>
              )}
            /> */}
            <FileInput
              sx={{ width: "100%" }}
              onChange={(newUrl) => {
                console.log("filte arrrrr", newUrl);
                setFields({ ...fields, document_url: newUrl });
              }}
              defaults={fields.document_url ? [fields.document_url] : undefined}
              accept=".pdf"
              title="Form pdf*"
              subTitle="Only pdf files are allowed! less than 2 MB"
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(KeyDocumentCreateUi);
